import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-sass-subscribe',
  templateUrl: './enterprice-sass-subscribe.component.html',
  styleUrls: ['./enterprice-sass-subscribe.component.scss']
})
export class EnterpriceSassSubscribeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
